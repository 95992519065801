/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-18",
    versionChannel: "nightly",
    buildDate: "2024-01-18T00:15:03.619Z",
    commitHash: "d89ab3dc835b0490611d50981c49bf14a7283bce",
};
